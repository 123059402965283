import {
  applyMiddleware,
  compose,
  createStore,
  combineReducers,
  Store,
  Middleware
} from "redux";
import createHistory from "history/createBrowserHistory";
import {
  RouterState,
  routerReducer as router,
  routerMiddleware
} from "react-router-redux";
import thunk from "redux-thunk";
import reducerRegistry, { Reducers } from "./common/helpers/reducerRegistry";

// tslint:disable-next-line:naming-convention
export const history = createHistory();

reducerRegistry.register("router", router);

function create(): Store<GlobalState> {
  const middlewares: Array<Middleware> = [thunk, routerMiddleware(history)];

  if (process.env.NODE_ENV !== "production") {
    const { createLogger } = require("redux-logger");
    middlewares.push(createLogger());
  }

  const composeEnhancers =
    (process.env.NODE_ENV !== "production" &&
      window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) ||
    compose;

  const createStoreWith = composeEnhancers(applyMiddleware(...middlewares))(
    createStore
  );

  return createStoreWith(
    combineReducers(reducerRegistry.reducers as any)
  ) as unknown as Store<GlobalState>;
}

const store = create();

reducerRegistry.setChangeListener((reducers: Partial<Reducers>) => {
  store.replaceReducer(combineReducers(reducers as any) as any);
});

export default store;

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
  }

  interface GlobalState {
    router: RouterState;
  }
}
