import "normalize.css";
import { injectGlobal } from "react-emotion";
import Color from "./colors";

// tslint:disable-next-line:no-unused-expression
injectGlobal`
  html {
    width: 100vw;
    height: 100vh;
    font-family: Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, 0.87);

    &.wf-active {
      font-family: "FaktPro";

      h1, h2, h3 {
        font-family: "Gilroy";
      }
    }
  }

  button {
    font-family: Helvetica, Arial, sans-serif;
    outline: none;
  }

  html.wf-active button {
    font-family: "FaktPro";
  }

  body, #root {
    display: flex;
    width: 100%;
    height: 100%;
  }

  body {
    margin: 0;
    font-size: 16px;
    overflow-x: hidden;

    @media screen and (max-width: 800px) {
      height: auto;
    }
  }

  #root {
    box-sizing: border-box;
    flex-direction: column;
    padding: constant(safe-area-inset-top) constant(safe-area-inset-right) constant(safe-area-inset-bottom) constant(safe-area-inset-left);
    padding: env(safe-area-inset-top) env(safe-area-inset-right) env(safe-area-inset-bottom) env(safe-area-inset-left);
    transition: filter 500ms cubic-bezier(0.25, 0.8, 0.25, 1);

    @media screen and (min-width: 551px) {
      &.modalOpen {
        > * {
          filter: grayscale(80%);
        }
      }
    }
  }

  h1, h2, h3 {
    font-weight: 500;
    margin: 0;
    margin-bottom: 25px;
  }

  h1 {
    font-size: 38px;
    color: ${Color.WorldLineSureSage700}
  }

  h2 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  @media screen and (max-width: 500px) {
    h1, h2, h3 {
      margin-bottom: 10px;
    }

    h1 {
      font-size: 28px;
    }

    h2 {
      font-size: 24px;
    }

    h3 {
      font-size: 20px;
    }
  }

  a {
    color: ${Color.WorldLineSureSage700};
    text-decoration: underline;
    transition: 200ms all ease-in-out;

    &:hover {
      opacity: 0.7;
    }

    &:focus {
      outline: 0;
    }

    body:not(.focus-by-click) &:focus {
      box-shadow: 0 0 2px 2px rgba(77,144,254,.5);
    }
  }

  button {
    font-family: Helvetica, Arial, sans-serif;
  }

  label {
    font-size: 16px;
    display: block;
    margin-bottom: 4px;
    font-weight: 500;
  }

  address {
    font-style: normal;
  }
`;

import "./scrollbar";

async function loadFonts() {
  const webFont = await import(/* webpackPreload: true */ "webfontloader");

  webFont.load({
    custom: {
      families: ["FaktPro:n4,n5,n6", "Gilroy:n4,n5,n6"],
      urls: ["https://static.bambora.com/assets/fonts/fonts.css"]
    }
  });
}

function detectUITraits() {
  const iOSdetected =
    /iPad|iPhone|iPod/.test(navigator.userAgent) && !(window as any).MSStream;

  if (!iOSdetected) return;

  document.body.classList.add("iOS");
}

loadFonts();
detectUITraits();
