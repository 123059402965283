enum AuthenticationAction {
  RequestSignIn = "[Request] Authentication:SignIn",
  ResolveSignIn = "[Resolve] Authentication:SignIn",
  RejectSignIn = "[Reject] Authentication:SignIn",

  RequestSessionSignIn = "[Request] Authentication:SessionSignIn",
  ResolveSessionSignIn = "[Resolve] Authentication:SessionSignIn",
  RejectSessionSignIn = "[Reject] Authentication:SessionSignIn",

  RequestOTPSignIn = "[Request] Authentication:OTPSignIn",
  ResolveOTPSignIn = "[Resolve] Authentication:OTPSignIn",
  RejectOTPSignIn = "[Reject] Authentication:OTPSignIn",

  RequestSignOut = "[Request] Authentication:SignOut",
  ResolveSignOut = "[Resolve] Authentication:SignOut",
  RejectSignOut = "[Reject] Authentication:SignOut",

  RequestForgotPassword = "[Request] Authentication:ForgotPassword",
  ResolveForgotPassword = "[Resolve] Authentication:ForgotPassword",
  RejectForgotPassword = "[Reject] Authentication:ForgotPassword",

  RequestResetPassword = "[Request] Authentication:ResetPassword",
  ResolveResetPassword = "[Resolve] Authentication:ResetPassword",
  RejectResetPassword = "[Reject] Authentication:ResetPassword",

  RequestForgotOTP = "[Request] Authentication:ForgotOTP",
  ResolveForgotOTP = "[Resolve] Authentication:ForgotOTP",
  RejectForgotOTP = "[Reject] Authentication:ForgotOTP",

  RequestResetOTP = "[Request] Authentication:ResetOTP",
  ResolveResetOTP = "[Resolve] Authentication:ResetOTP",
  RejectResetOTP = "[Reject] Authentication:ResetOTP",

  RequestListFunctionPermissions = "[Request] Authentication:ListFunctionPermissions",
  ResolveListFunctionPermissions = "[Resolve] Authentication:ListFunctionPermissions",
  RejectListFunctionPermissions = "[Reject] Authentication:ListFunctionPermissions",

  RequestImpersonate = "[Request] Authentication:Impersonate",
  ResolveImpersonate = "[Resolve] Authentication:Impersonate",
  RejectImpersonate = "[Reject] Authentication:Impersonate",

  RequestExternal = "[Request] Authentication:External",
  ResolveExternal = "[Resolve] Authentication:External",
  RejectExternal = "[Reject] Authentication:External",

  RequestCreateAccount = "[Request] Authentication:CreateAccount",
  ResolveCreateAccount = "[Resolve] Authentication:CreateAccount",
  RejectCreateAccount = "[Reject] Authentication:CreateAccount",

  SetActiveMerchantNumber = "[Request] Authentication:SetActiveMerchant"
}

export default AuthenticationAction;
