import * as React from "react";
import { Link } from "react-router-dom";
import styled from "react-emotion";
import SvgLoader from "./svgLoader";
import Color from "../styles/colors";

// tslint:disable-next-line:naming-convention
const Container = styled("div")`
  display: flex;
  height: 350px;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

// tslint:disable-next-line:naming-convention
const Label = styled("div")`
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    line-height: 80%;
  }

  > div:nth-child(1) {
    font-size: 100px;
    font-weight: bold;
    color: ${Color.WorldLineSureSage700};
  }

  > div:nth-child(2) {
    font-size: 86px;
  }

  > div:nth-child(3) {
    font-size: 46px;
    font-weight: bold;
  }
`;

// tslint:disable-next-line:naming-convention
const HomeLink = styled("div")`
  display: flex;
  margin-top: 50px;
  flex-direction: column;
  align-items: center;
`;

export default class NoMatch extends React.PureComponent {
  labelElement!: HTMLDivElement;

  componentDidMount() {
    window.addEventListener("mousemove", this.tiltOnMouseMove);
  }

  componentWillUnmount() {
    window.removeEventListener("mousemove", this.tiltOnMouseMove);
  }

  tiltOnMouseMove = (event: MouseEvent) => {
    const skew = {
      y: 40 * (event.x / window.innerWidth - 0.5),
      x: -(40 * (event.y / window.innerHeight - 0.5))
    };

    this.labelElement.style.transform =
      `perspective(${document.body.clientHeight}px)` +
      `rotateX(${skew.x}deg) rotateY(${skew.y}deg)`;
  };

  render() {
    return (
      <Container>
        <Label
          innerRef={(labelElement: HTMLDivElement) =>
            (this.labelElement = labelElement)
          }
        >
          <div>404</div>
          <div>NOT</div>
          <div>FOUND</div>
        </Label>

        <HomeLink>
          <SvgLoader src={require("../assets/svg/home.svg")} />
          <Link to="/">Home</Link>
        </HomeLink>
      </Container>
    );
  }
}
