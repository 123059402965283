import "whatwg-fetch";
import * as React from "react";
import { render } from "react-dom";

import Provider from "react-redux/es/components/Provider";

import "./common/styles/global";
import store, { history } from "./store";
import routes from "./routes";
import initA11yFocus from "./common/helpers/a11yFocus";
import ConnectedRouter from "react-router-redux/es/ConnectedRouter";

function bootstrap() {
  const rootElement = document.createElement("div");

  rootElement.id = "root";
  document.body.appendChild(rootElement);

  render(
    <Provider store={store}>
      <ConnectedRouter history={history}>{routes}</ConnectedRouter>
    </Provider>,
    rootElement
  );
}

// tslint:disable:no-console
async function registerServiceWorker() {
  if (!("serviceWorker" in navigator)) return;

  if (process.env.DISABLE_SW) {
    const registrations = await navigator.serviceWorker.getRegistrations();

    registrations.map(async registration => {
      const result = await registration.unregister();

      result
        ? console.log("SW unregistered: ", registration)
        : console.log("SW unregistration failed: ", registration);
    });

    return;
  }

  const registerPromise = navigator.serviceWorker.register("/sw.js");

  if (process.env.NODE_ENV !== "development") return;

  try {
    const registration = await registerPromise;
    console.log("SW registered: ", registration);
  } catch (error) {
    console.log("SW registration failed: ", error);
  }
}
// enable:disable:no-console

bootstrap();
registerServiceWorker();
initA11yFocus();
