import * as React from "react";
import styled, { StyledComponent } from "react-emotion";

// tslint:disable-next-line:naming-convention
const DefaultWrapper = styled("div")`
  display: flex;
`;

interface SvgLoaderProps {
  src: string;
  wrapper?: React.ComponentClass<any> | StyledComponent<any, any, any>;
}

type Props = React.HTMLAttributes<HTMLElement> & SvgLoaderProps;

interface State {
  svgPromise: Promise<string> | null;
  svg: string | null;
}

const cache: { [key: string]: Promise<string> } = {};

export default class SvgLoader extends React.PureComponent<Props, State> {
  cancelableFetchPromise!: CancelablePromise<string>;

  constructor(props: Props) {
    super(props);

    this.loadSvgFromCacheOrNetwork((state: State) => (this.state = state));
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.src === prevProps.src) return;

    this.loadSvgFromCacheOrNetwork((state: State) => this.setState(state));
  }

  loadSvgFromCacheOrNetwork(setState: (state: State) => void) {
    const cacheEntry = cache[this.props.src];

    if (cacheEntry) {
      setState({ svgPromise: cacheEntry, svg: null });
      this.cancelableFetchPromise = makeCancelable(cacheEntry);

      this.cancelableFetchPromise.promise.then(
        svg => this.setState({ svg }),
        () => undefined
      );

      return;
    }

    const svgPromise = fetch(this.props.src).then(result => result.text());
    cache[this.props.src] = svgPromise;

    this.cancelableFetchPromise = makeCancelable(svgPromise);

    setState({ svgPromise, svg: null });

    this.cancelableFetchPromise.promise.then(
      svg => this.setState({ svg }),
      () => undefined
    );
  }

  componentWillUnmount() {
    this.cancelableFetchPromise.cancel();
  }

  render() {
    // tslint:disable:naming-convention
    // tslint:disable:no-unused
    const { wrapper: Wrapper, src, ...rest } = this.props;
    // tslint:enable:naming-convention
    // tslint:enable:no-unused

    if (!this.state.svg) return null;

    const props = {
      dangerouslySetInnerHTML: { __html: this.state.svg }
    };

    if (!Wrapper) return <DefaultWrapper {...rest} {...props} />;

    return <Wrapper {...rest} {...props} />;
  }
}

export interface CancelablePromise<T = any> {
  promise: Promise<T>;
  cancel(): void;
}

export function makeCancelable<T>(promise: Promise<T>): CancelablePromise<T> {
  let hasCanceled = false;

  const wrappedPromise = new Promise<T>((resolve, reject) => {
    promise.then(
      value => (hasCanceled ? reject({ isCanceled: true }) : resolve(value)),
      error => (hasCanceled ? reject({ isCanceled: true }) : reject(error))
    );
  });

  return {
    promise: wrappedPromise,
    cancel() {
      hasCanceled = true;
    }
  };
}
