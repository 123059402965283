import { CreateAction } from "../helpers/actions";
import * as loginClient from "../clients/login";
import * as merchantClient from "../clients/merchant";
import Common from "../clients/commonTypes";
import AuthenticationAction from "./actions";
import { ExternalSession } from "./helpers";

const createAction: CreateAction<AuthenticationAction> = payload => payload;

// #region SignIn
export const requestSignIn = (payload: loginClient.Request.LogOnMerchant) =>
  createAction({ payload, type: AuthenticationAction.RequestSignIn });

export const resolveSignIn = (payload: loginClient.Response.LogOnMerchant) =>
  createAction({ payload, type: AuthenticationAction.ResolveSignIn });

export const rejectSignIn = (payload: Response | null) =>
  createAction({ payload, type: AuthenticationAction.RejectSignIn });

export const requestSessionSignIn = (
  payload: loginClient.Request.SessionLogOnMerchant
) => createAction({ payload, type: AuthenticationAction.RequestSessionSignIn });

export const resolveSessionSignIn = (
  payload: loginClient.Response.SessionLogOnMerchant
) => createAction({ payload, type: AuthenticationAction.ResolveSessionSignIn });

export const rejectSessionSignIn = (payload: Response | null | unknown) =>
  createAction({ payload, type: AuthenticationAction.RejectSessionSignIn });

export const requestOTPSignIn = (
  payload: loginClient.Request.OtpLogOnMerchant
) => createAction({ payload, type: AuthenticationAction.RequestOTPSignIn });

export const resolveOTPSignIn = (
  payload: loginClient.Response.OtpLogOnMerchant
) => createAction({ payload, type: AuthenticationAction.ResolveOTPSignIn });

export const rejectOTPSignIn = (payload: Response | null | unknown) =>
  createAction({ payload, type: AuthenticationAction.RejectOTPSignIn });
// #endregion SignIn

// #region SignOut
export const requestSignOut = (payload: AccessToken) =>
  createAction({ payload, type: AuthenticationAction.RequestSignOut });

export const resolveSignOut = (
  payload: loginClient.Response.LogOffMerchant & AccessToken
) => createAction({ payload, type: AuthenticationAction.ResolveSignOut });

export const rejectSignOut = (payload: {
  response: Response | null | unknown;
  accessToken: string;
}) => createAction({ payload, type: AuthenticationAction.RejectSignOut });
// #endregion SignOut

// #region ForgotPassword
export const requestForgotPassword = (
  payload: loginClient.Request.ForgotPassword
) =>
  createAction({ payload, type: AuthenticationAction.RequestForgotPassword });

export const resolveForgotPassword = (
  payload: loginClient.Response.ForgotPasswordMerchant
) =>
  createAction({ payload, type: AuthenticationAction.ResolveForgotPassword });

export const rejectForgotPassword = (payload: Response | null | unknown) =>
  createAction({ payload, type: AuthenticationAction.RejectForgotPassword });
// #endregion ForgotPassword

// #region ResetPassword
export const requestResetPassword = (
  payload: loginClient.Request.ResetPassword
) => createAction({ payload, type: AuthenticationAction.RequestResetPassword });

export const resolveResetPassword = (
  payload: loginClient.Response.ResetPasswordMerchant
) => createAction({ payload, type: AuthenticationAction.ResolveResetPassword });

export const rejectResetPassword = (payload: Response | null | unknown) =>
  createAction({ payload, type: AuthenticationAction.RejectResetPassword });
// #endregion ResetPassword

// #region ForgotOTP
export const requestForgotOTP = (payload: loginClient.Request.ForgotOTP) =>
  createAction({ payload, type: AuthenticationAction.RequestForgotOTP });

export const resolveForgotOTP = (
  payload: loginClient.Response.ForgotOTPMerchant
) => createAction({ payload, type: AuthenticationAction.ResolveForgotOTP });

export const rejectForgotOTP = (payload: Response | null | unknown) =>
  createAction({ payload, type: AuthenticationAction.RejectForgotOTP });
// #endregion ForgotOTP

// #region ResetOTP
export const requestResetOTP = (payload: loginClient.Request.ResetOTP) =>
  createAction({ payload, type: AuthenticationAction.RequestResetOTP });

export const resolveResetOTP = (
  payload: loginClient.Response.ResetOTPMerchant
) => createAction({ payload, type: AuthenticationAction.ResolveResetOTP });

export const rejectResetOTP = (payload: Response | null | unknown) =>
  createAction({ payload, type: AuthenticationAction.RejectResetOTP });
// #endregion ResetOTP

// #region Impersonate
export const requestImpersonate = (payload: AuthorizationToken) =>
  createAction({ payload, type: AuthenticationAction.RequestImpersonate });

export const resolveImpersonate = (
  payload: loginClient.Response.LogOnMerchant &
    AuthorizationToken &
    ActiveMerchantNumber
) => createAction({ payload, type: AuthenticationAction.ResolveImpersonate });

export const rejectImpersonate = (payload: Response | null | unknown) =>
  createAction({ payload, type: AuthenticationAction.RejectImpersonate });
// #endregion Impersonate

// #region External
export const requestExternal = (payload: AuthorizationToken) =>
  createAction({ payload, type: AuthenticationAction.RequestExternal });

export const resolveExternal = (payload: ExternalSession) =>
  createAction({ payload, type: AuthenticationAction.ResolveExternal });

export const rejectExternal = (payload: Response | Error | null | unknown) =>
  createAction({ payload, type: AuthenticationAction.RejectExternal });
// #endregion External

// #region CreateAccount
export const requestCreateAccount = (
  payload: merchantClient.Request.WebUser.AddWebUser
) => createAction({ payload, type: AuthenticationAction.RequestCreateAccount });

export const resolveCreateAccount = (
  payload: [merchantClient.Response.WebUser.AddWebUser, Common.Response]
) => createAction({ payload, type: AuthenticationAction.ResolveCreateAccount });

export const rejectCreateAccount = (payload: Response | null | unknown) =>
  createAction({ payload, type: AuthenticationAction.RejectCreateAccount });
// #endregion CreateAccount

// #region SetActiveMerchantNumber
export const setActiveMerchantNumber = (payload: ActiveMerchantNumber) =>
  createAction({
    payload,
    type: AuthenticationAction.SetActiveMerchantNumber
  });
// #endregion SetActiveMerchantNumber

interface AccessToken {
  accessToken: string;
}

interface AuthorizationToken {
  authorizationToken: string;
}

interface ActiveMerchantNumber {
  activeMerchantNumber: string;
}
