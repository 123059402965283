enum CommonAction {
  RequestLoadMerchantsList = "[Request] Merchants:LoadList",
  ResolveLoadMerchantsList = "[Resolve] Merchants:LoadList",
  RejectLoadMerchantsList = "[Reject] Merchants:LoadList",

  RequestEditMerchant = "[Request] Merchant:Edit",
  ResolveEditMerchant = "[Resolve] Merchant:Edit",
  RejectEditMerchant = "[Reject] Merchant:Edit",

  RequestLoadPermissions = "[Request] Permissions:Load",
  ResolveLoadPermissions = "[Resolve] Permissions:Load",
  RejectLoadPermissions = "[Reject] Permissions:Load",

  RequestLoadAccessTokenPermissions = "[Request] AccessTokenPermissions:Load",
  ResolveLoadAccessTokenPermissions = "[Resolve] AccessTokenPermissions:Load",
  RejectLoadAccessTokenPermissions = "[Reject] AccessTokenPermissions:Load",

  RequestLoadResponseCodes = "[Request] ResponseCodes:Load",
  ResolveLoadResponseCodes = "[Resolve] ResponseCodes:Load",
  RejectLoadResponseCodes = "[Reject] ResponseCodes:Load",

  RequestLoadCurrencies = "[Request] Currencies:Load",
  ResolveLoadCurrencies = "[Resolve] Currencies:Load",
  RejectLoadCurrencies = "[Reject] Currencies:Load",

  RequestLoadPaymentTypes = "[Request] Main:LoadPaymentTypes",
  ResolveLoadPaymentTypes = "[Resolve] Main:LoadPaymentTypes",
  RejectLoadPaymentTypes = "[Reject] Main:LoadPaymentTypes",

  RequestLoadAcquirers = "[Request] Main:LoadAcquirers",
  ResolveLoadAcquirers = "[Resolve] Main:LoadAcquirers",
  RejectLoadAcquirers = "[Reject] Main:LoadAcquirers"
}

export default CommonAction;
