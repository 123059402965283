import * as React from "react";
import { Route, Switch, Redirect } from "react-router";
import NoMatch from "./common/components/noMatch";
import getLoadable from "./common/helpers/getLoadable";

// tslint:disable:naming-convention
const AuthenticateLazy = getLoadable()(() =>
  import(/* webpackPreload: true */ "./authentication/authenticate")
);

const MainLazy = getLoadable()(() =>
  import(/* webpackPreload: true */ "./common/components/main")
);
// tslint:enable:naming-convention

export default (
  <Switch>
    <Route exact path="/">
      <Redirect to="/auth/signin" />
    </Route>
    <Route path="/auth" component={AuthenticateLazy} />
    <Route
      exact
      path="/:merchantnumber"
      render={({ match }) => (
        <Redirect to={`/${match.params.merchantnumber}/payments`} />
      )}
    />
    <Route path="/:merchantnumber" component={MainLazy} />
    <Route component={NoMatch} />
  </Switch>
);
