import Loadable from "react-loadable";
import Loader from "../components/loader";

export default (loader: React.ComponentType<any> | (() => null) = Loader) => (
  dynamicImportFunction: () => Promise<any>
) =>
  Loadable<any, any>({
    loader: dynamicImportFunction,
    delay: 200,
    loading: loader
  });
