import * as React from "react";
import styled, { keyframes } from "react-emotion";
import Color from "../styles/colors";

const size = 90;
const duration = 5000;
const color = "currentColor";
const thickness = 5;
const timing = "cubic-bezier(0.35, 0, 0.25, 1)";
const rotateDegrees = 135;

const loaderAnimation = keyframes`
  0% { transform: rotate(0); }
  100% { transform: rotate(359deg); }
`;

const strokeContainerAnimation = keyframes`
  0% { transform: rotate(${0 * rotateDegrees}deg); }
  12.5% { transform: rotate(${1 * rotateDegrees}deg); }
  25% { transform: rotate(${2 * rotateDegrees}deg); }
  37.5% { transform: rotate(${3 * rotateDegrees}deg); }
  50% { transform: rotate(${4 * rotateDegrees}deg); }
  62.5% { transform: rotate(${5 * rotateDegrees}deg); }
  75% { transform: rotate(${6 * rotateDegrees}deg); }
  87.5% { transform: rotate(${7 * rotateDegrees}deg); }
  100% { transform: rotate(${8 * rotateDegrees}deg); }
`;

const leftStrokeAnimation = keyframes`
  0%, 100% { transform: rotate(310deg); }
  50% { transform: rotate(175deg); }
`;

const rightStrokeAnimation = keyframes`
  0%, 100% { transform: rotate(-40deg); }
  50% { transform: rotate(95deg); }
`;

// tslint:disable:naming-convention

const Loader = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  align-self: center;
  justify-self: center;
  color: ${Color.WorldLineSureSage700};

  h1 {
    margin-bottom: 0;
    margin-top: 0;
    color: ${Color.WorldLineSureSage700};
    font-size: 30px;
    font-weight: 400;
  }

  &.error {
    flex-direction: column;

    h1 {
      color: ${Color.WorldLineError500};
    }

    p {
      font-size: 24px;
      font-weight: 200;
    }
  }
`;

const CircleContainer = styled("div")`
  transform: scale(0.4);
`;

const Circle = styled("div")`
  display: block;
  width: ${size}px;
  height: ${size}px;
  position: relative;
  animation: ${loaderAnimation} ${duration / 1.8}ms linear both infinite;
  box-sizing: border-box;
`;

const StrokeContainer = styled("div")`
  display: block;
  height: ${size}px;
  position: absolute;
  top: 0;
  width: ${size / 2}px;
  overflow: hidden;
  animation: ${strokeContainerAnimation} ${duration}ms ${timing} both infinite;
  box-sizing: border-box;
`;

const LeftStrokeContainer = styled(StrokeContainer)`
  left: 0;
  transform-origin: center right;
`;

const RightStrokeContainer = styled(StrokeContainer)`
  right: 0;
  transform-origin: center left;
`;

const Stroke = styled("div")`
  display: block;
  border: ${thickness}px solid ${color};
  border-radius: 50%;
  width: ${size}px;
  height: ${size}px;
  position: absolute;
  box-sizing: border-box;
`;

const LeftStroke = styled(Stroke)`
  left: 0;
  border-left-color: transparent;
  border-top-color: transparent;
  animation: ${leftStrokeAnimation} ${duration / 4}ms ${timing} both infinite;
`;

const RightStroke = styled(Stroke)`
  right: 0;
  border-right-color: transparent;
  border-bottom-color: transparent;
  animation: ${rightStrokeAnimation} ${duration / 4}ms ${timing} both infinite;
`;
// tslint:enable:naming-convention

interface LoaderProps {
  message?: string;
  error?: boolean;
  pastDelay?: boolean;
  hideMessage?: boolean;
}

// tslint:disable-next-line:naming-convention
export const CircularLoader = () => (
  <CircleContainer>
    <Circle>
      <LeftStrokeContainer>
        <LeftStroke />
      </LeftStrokeContainer>
      <RightStrokeContainer>
        <RightStroke />
      </RightStrokeContainer>
    </Circle>
  </CircleContainer>
);

export default (props: LoaderProps) => {
  if (props.error) {
    return (
      <Loader className="error">
        <h1>Something went wrong.</h1>
        <p>Try reloading the page.</p>
      </Loader>
    );
  }

  if (props.pastDelay) {
    const message = props.message || "Loading...";

    return (
      <Loader>
        <CircularLoader />
        {!props.hideMessage && <h1>{message}</h1>}
      </Loader>
    );
  }

  return null;
};
