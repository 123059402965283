import { ActionUnion } from "../helpers/actions";
import * as actionCreators from "./actionCreators";
import * as commonActionCreators from "../actionCreators";
import AuthenticationAction from "./actions";
import * as loginClient from "../clients/login";
import CommonAction from "../actions";
import unionWith from "ramda/es/unionWith";
import eqBy from "ramda/es/eqBy";
import prop from "ramda/es/prop";
import {
  getAuthorizationToken,
  createPersistentSession,
  removePersistentSession,
  getPersistentSession,
  UserSession,
  ExternalSession,
  WebUserSession,
  ImpersonateUserSession,
  BaseSession
} from "./helpers";
import generateUuid from "../helpers/generateUuid";

// tslint:disable-next-line:naming-convention
export const reducerName = "authentication";

const initialState: AuthenticationState = {
  session: null,
  isLoading: false,
  error: null
};

export default function reducer(
  state = initialState,
  action: ActionUnion<typeof actionCreators & typeof commonActionCreators>
): AuthenticationState {
  switch (action.type) {
    // #region SignIn
    case AuthenticationAction.RequestSignIn: {
      return {
        ...state,
        session: null,
        isLoading: true,
        error: null
      };
    }

    case AuthenticationAction.ResolveSignIn: {
      const { merchantnumbers, accesstoken, secrettoken } = action.payload;

      const persistentSession = getPersistentSession(accesstoken);

      if (persistentSession) {
        return {
          ...state,
          session: persistentSession,
          isLoading: false
        };
      }

      const defaultMerchantNumber = merchantnumbers[0];

      const authorizationToken = getAuthorizationToken(
        defaultMerchantNumber.number,
        accesstoken,
        secrettoken
      );

      const logCollationId = generateUuid();

      const session: WebUserSession = {
        ...action.payload,
        authorizationToken,
        logCollationId,
        type: "webUser",
        activeMerchantNumber: defaultMerchantNumber.number
      };

      createPersistentSession(session);

      return {
        ...state,
        session,
        isLoading: false
      };
    }

    case AuthenticationAction.RejectSignIn: {
      return {
        ...state,
        isLoading: false,
        error: {
          message: null
        }
      };
    }
    // #endregion SignIn

    // #region OTP SignIn
    case AuthenticationAction.RequestOTPSignIn: {
      return {
        ...state,
        session: null,
        isLoading: true,
        error: null
      };
    }

    case AuthenticationAction.ResolveOTPSignIn: {
      const { merchantnumbers, accesstoken, secrettoken } = action.payload;

      const persistentSession = getPersistentSession(accesstoken);

      if (persistentSession) {
        return {
          ...state,
          session: persistentSession,
          isLoading: false
        };
      }

      const defaultMerchantNumber = merchantnumbers[0];

      const authorizationToken = getAuthorizationToken(
        defaultMerchantNumber.number,
        accesstoken,
        secrettoken
      );

      const logCollationId = generateUuid();

      const session: WebUserSession = {
        ...action.payload,
        authorizationToken,
        logCollationId,
        type: "webUser",
        activeMerchantNumber: defaultMerchantNumber.number
      };

      createPersistentSession(session);

      return {
        ...state,
        session,
        isLoading: false
      };
    }

    case AuthenticationAction.RejectOTPSignIn: {
      return {
        ...state,
        isLoading: false,
        error: {
          message: null
        }
      };
    }
    // #endregion OTPSignIn

    // #region Session SignIn
    case AuthenticationAction.RequestSessionSignIn: {
      return {
        ...state,
        session: null,
        isLoading: true,
        error: null
      };
    }

    case AuthenticationAction.RejectSessionSignIn: {
      return {
        ...state,
        isLoading: false,
        error: {
          message: null
        }
      };
    }
    // #endregion OTPSignIn

    // #region SignOut
    case AuthenticationAction.RequestSignOut: {
      return {
        ...state,
        isLoading: true,
        error: null
      };
    }

    case AuthenticationAction.ResolveSignOut: {
      removePersistentSession(action.payload.accessToken);

      return {
        ...state,
        session: null,
        isLoading: false,
        error: null
      };
    }

    case AuthenticationAction.RejectSignOut: {
      const { accessToken } = action.payload;

      removePersistentSession(accessToken);

      return {
        ...state,
        session: null,
        isLoading: false,
        error: {
          message: null
        }
      };
    }
    // #endregion SignOut

    // #region Impersonate
    case AuthenticationAction.RequestImpersonate: {
      return {
        ...state,
        session: null,
        isLoading: true,
        error: null
      };
    }

    case AuthenticationAction.ResolveImpersonate: {
      const logCollationId = generateUuid();

      const session: ImpersonateUserSession = {
        ...action.payload,
        logCollationId,
        type: "impersonate"
      };

      createPersistentSession(session);

      return {
        ...state,
        session,
        isLoading: false
      };
    }

    case AuthenticationAction.RejectImpersonate: {
      return {
        ...state,
        isLoading: false,
        error: {
          message: null
        }
      };
    }
    // #endregion Impersonate

    // #region External
    case AuthenticationAction.RequestExternal: {
      return {
        ...state,
        session: null,
        isLoading: true,
        error: null
      };
    }

    case AuthenticationAction.ResolveExternal: {
      const logCollationId = generateUuid();

      const session: ExternalSession = {
        ...action.payload,
        logCollationId
      };

      createPersistentSession(session);

      return {
        ...state,
        session,
        isLoading: false
      };
    }

    case AuthenticationAction.RejectExternal: {
      return {
        ...state,
        isLoading: false,
        error: {
          message: null
        }
      };
    }
    // #endregion External

    // #region ActiveMerchantNumber
    case AuthenticationAction.SetActiveMerchantNumber: {
      if (!state.session) return state;

      const nextActiveMerchantNumber = action.payload.activeMerchantNumber;

      let authorizationToken = state.session.authorizationToken;

      if (
        state.session.type === "webUser" ||
        state.session.type === "impersonate"
      ) {
        const userSession: UserSession = state.session as UserSession;

        authorizationToken = getAuthorizationToken(
          nextActiveMerchantNumber,
          userSession.accesstoken,
          userSession.secrettoken
        );
      }

      const session: BaseSession = {
        ...state.session,
        authorizationToken,
        activeMerchantNumber: nextActiveMerchantNumber
      };

      createPersistentSession(session);

      return {
        ...state,
        session
      };
    }
    // #endregion ActiveMerchantNumber

    case CommonAction.ResolveLoadMerchantsList: {
      if (!state.session) {
        return {
          ...state
        };
      }

      const merchantNumbers = [...state.session.merchantnumbers];

      const newMerchantNumbers: Array<
        loginClient.Response.MerchantNumber
      > = action.payload.map(merchant => ({
        number: merchant.number,
        description: merchant.description
      }));

      const unionMerchantNumbers = unionWith(
        eqBy(prop("number") as any),
        newMerchantNumbers,
        merchantNumbers
      );

      const session = { ...state.session };
      session.merchantnumbers = unionMerchantNumbers;

      createPersistentSession(session);

      return {
        ...state,
        session
      };
    }

    case CommonAction.ResolveEditMerchant: {
      if (!state.session) {
        return {
          ...state
        };
      }

      const merchantNumbers = [...state.session.merchantnumbers];
      const merchant = merchantNumbers.find(
        x => x.number === action.payload.number
      );

      if (!merchant) {
        return {
          ...state
        };
      }

      merchant.description = action.payload.description || merchant.description;
      const session = { ...state.session };
      session.merchantnumbers = merchantNumbers;

      createPersistentSession(session);

      return {
        ...state,
        session
      };
    }
  }

  return state;
}

export interface AuthenticationState {
  session: BaseSession | null;
  isLoading: boolean;
  error: GenericUIError;
}

declare global {
  interface GlobalState {
    [reducerName]: ReturnType<typeof reducer>;
  }
}
