import store from "../../store";
import { Response } from "../clients/login";
import {
  resolveSignIn,
  resolveImpersonate,
  resolveExternal
} from "./actionCreators";

export function containsFunctionPermission(
  permissions: Array<Response.FunctionPermission>,
  functionName: string
): boolean {
  return permissions.find(fp => fp.name === functionName) !== undefined;
}

export function getErrorIfMissingFunctionPermission(
  permissions: Array<Response.FunctionPermission>,
  functionName: string
): GenericUIError | undefined {
  if (containsFunctionPermission(permissions, functionName)) {
    return;
  }

  return {
    message: {
      title: "Access denied!",
      description: `You're missing the function permission: ${functionName}`,
      icon: "/assets/svg/icon-color-locked.svg"
    }
  };
}

export function isAuthenticated(merchantNumber: string | null): boolean {
  const { authentication } = store.getState();
  const authenticated = !!authentication.session;

  if (authenticated) return true;

  const [session] = getPersistentSessions(merchantNumber);

  if (session) {
    switch (session.type) {
      case "webUser":
        store.dispatch(resolveSignIn(session as WebUserSession));
      case "impersonate":
        store.dispatch(resolveImpersonate(session as ImpersonateUserSession));
      case "external":
        store.dispatch(resolveExternal(session as ExternalSession));
    }

    return true;
  }

  return false;
}

export function getAuthorizationToken(
  merchantNumber: string,
  accessToken: string,
  secretToken: string
): string {
  return btoa(`${accessToken}@${merchantNumber}:${secretToken}`);
}

export function getCredentialsFromAuthorizationToken(token: string) {
  const decodedToken = atob(token);
  const [accessToken, rest] = decodedToken.split("@");
  const [merchantNumber, secretToken] = rest.split(":");
  const merchantAccessToken = accessToken.split("-")[0];

  return {
    merchantNumber,
    accessToken,
    secretToken,
    merchantAccessToken
  };
}

const SESSION_STORAGE_KEY = "sessions";

export function createPersistentSession(session: BaseSession) {
  const stringifiedSessions =
    window.localStorage.getItem(SESSION_STORAGE_KEY) || "[]";

  const sessions: Array<BaseSession> = JSON.parse(stringifiedSessions);

  // Remove any existing sessions that may conflict:
  const newSessions =
    session.type === "webUser"
      ? sessions.filter(({ type }) => type !== session.type)
      : sessions.filter(
          ({ accesstoken }) => accesstoken !== session.accesstoken
        );

  newSessions.push(session);

  window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newSessions));
}

export function getPersistentSession(accessToken: string): BaseSession | null {
  const stringifiedSessions =
    window.localStorage.getItem(SESSION_STORAGE_KEY) || "[]";

  const sessions: Array<BaseSession> = JSON.parse(stringifiedSessions);
  const [session] = sessions.filter(s => s.accesstoken === accessToken);

  return session || null;
}

export function getPersistentSessions(
  merchantNumber: string | null
): Array<BaseSession> {
  const stringifiedSessions =
    window.localStorage.getItem(SESSION_STORAGE_KEY) || "[]";

  const sessions: Array<BaseSession> = JSON.parse(stringifiedSessions);

  // If merchant number is not provided, find a web user session:
  if (merchantNumber === null) {
    const webUsers = sessions.filter(({ type }) => type === "webUser");

    if (webUsers.length === 0) {
      return sessions.filter(({ type }) => type === "impersonate");
    }

    return webUsers;
  }

  // Otherwise, find any sessions that have access to the merchant number:
  return sessions.filter(
    ({ merchantnumbers }) =>
      merchantnumbers
        .map(({ number }) => number)
        .filter(number => number === merchantNumber).length
  );
}

export function removePersistentSession(accessToken: string | null): void {
  const stringifiedSessions =
    window.localStorage.getItem(SESSION_STORAGE_KEY) || "[]";

  const sessions: Array<BaseSession> = JSON.parse(stringifiedSessions);

  const newSessions = sessions.filter(
    session => session.accesstoken !== accessToken
  );

  window.localStorage.setItem(SESSION_STORAGE_KEY, JSON.stringify(newSessions));
}

type SessionType = "webUser" | "impersonate" | "external";

export interface BaseSession {
  type: SessionType;
  accesstoken: string;
  authorizationToken: string;
  activeMerchantNumber: string;
  logCollationId: string;
  merchantnumbers: Array<Response.MerchantNumber>;
  name?: string;
  email: string;
}

export interface UserSession extends BaseSession, Response.LogOnMerchant {
  type: "webUser" | "impersonate";
}

export interface Session<T extends SessionType> extends BaseSession {
  type: T;
}

export interface WebUserSession extends UserSession {
  type: "webUser";
}

export interface ImpersonateUserSession extends UserSession {
  type: "impersonate";
}

export interface ExternalSession extends Session<"external"> {
  type: "external";
  logoutRedirectUri: string | null;
  logoutText: string | null;
}
