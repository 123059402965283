export default {
  // Official WorldLine colors:
  WorldLineSureSage700: "#236D6D",
  WorldLineSureSage700Transparent: "#bdd3d3",
  WorldLineSureSage800: "#1D5959",
  WorldLineConfidentCorn700: "#D8B700",
  WorldLineConfidentCorn500: "#FFEB78",
  WorldLineConfidentCorn900: "#877300",
  WorldLineBody: "#575665",
  WorldLineSuccess500: "#008556",
  WorldLineError500: "#D81B2F",
  WorldLineBrandingbg: "#EFEFEF",
  WorldLineRowBackground: "#FAFAFA",
  White: "#FFFFFF",
  WorldLineSureSage500: "#2D8C8C",
  WorldLineInfo500: "#005A8C",
  WorldLineInfo100: "#E9FFFD"
};
