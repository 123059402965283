import { injectGlobal } from "react-emotion";
import KeyCode from "./keyCodes";

const focusByClickClassName = "focus-by-click";

// tslint:disable-next-line:no-unused
function clickHandler(event: MouseEvent) {
  document.body.classList.add(focusByClickClassName);

  window.removeEventListener("click", clickHandler);
  window.addEventListener("keydown", keydownHandler);
}

function keydownHandler(event: KeyboardEvent) {
  if (
    event.keyCode !== KeyCode.Tab &&
    event.keyCode !== KeyCode.Space &&
    event.keyCode !== KeyCode.Left &&
    event.keyCode !== KeyCode.Right &&
    event.keyCode !== KeyCode.Up &&
    event.keyCode !== KeyCode.Down
  ) {
    return;
  }

  document.body.classList.remove(focusByClickClassName);

  window.removeEventListener("keydown", keydownHandler);
  window.addEventListener("click", clickHandler);
}

export default function init() {
  // tslint:disable-next-line:no-unused-expression
  injectGlobal`
    .${focusByClickClassName} *:focus {
      outline: 0;
    }
  `;

  window.addEventListener("click", clickHandler);
}
