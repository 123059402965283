import { injectGlobal } from "react-emotion";
import Color from "./colors";

// tslint:disable-next-line:no-unused-expression
injectGlobal`
  ::-webkit-scrollbar {
    width: 7px;
    height: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
  }
  ::-webkit-scrollbar-thumb {
    background: rgb(140, 140, 140);
    border: 0 none #fff;
    border-radius: 50px;

    &:hover,
    &:active {
      background: ${Color.WorldLineSureSage700};
    }
  }
  ::-webkit-scrollbar-track {
    background: rgb(200, 200, 200);
    border: 0 none #fff;

    &:hover,
    &:active {
      background: rgb(200, 200, 200);
    }
  }
  ::-webkit-scrollbar-corner {
    background: transparent;
  }
`;
