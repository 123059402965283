export default function generateUuid() {
  try {
    const uuidParts = [
      generateString(8),
      generateString(4),
      generateString(4),
      generateString(4),
      generateString(12)
    ];

    return uuidParts.join("-");
  } catch {
    // Use fallback if crypto is not available:
    const uuidParts = [
      `${generateStringOfLength4()}${generateStringOfLength4()}`,
      generateStringOfLength4(),
      generateStringOfLength4(),
      generateStringOfLength4(),
      `${generateStringOfLength4()}${generateStringOfLength4()}${generateStringOfLength4()}`
    ];

    return uuidParts.join("-");
  }
}

function generateString(length: number = 40): string {
  const randomValues = new Uint8Array(length / 2);
  const crypto = window.crypto || window.msCrypto;

  crypto.getRandomValues(randomValues);

  return Array.from(randomValues, decimalToHex).join("");
}

function decimalToHex(decimal: number) {
  return `0${decimal.toString(16)}`.substr(-2);
}

function generateStringOfLength4(): string {
  return Math.floor((1 + Math.random()) * 0x10000)
    .toString(16)
    .substring(1);
}

declare global {
  interface Window {
    msCrypto: Crypto;
  }
}
